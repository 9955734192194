.notification-user-table-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & > div {
    padding: .5em;
  }
  & > .delete-user-notification-button {
    &:hover {
      background-color: rgba(240, 240, 240, 1);
    }

    background-image: url('../../../../../assets/icons/delete.svg');
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: 1.1em;
    background-position: center;

    cursor: pointer;
    width: 5%;
  }
}

@media (max-width: 1224px) {
  .notification-user-table-row {
    & > .delete-user-notification-button {
      background-size: 1em;
    }
  }
}
@import "src/css/constants.scss";

.error-view {
  padding: 25px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > h1 {
    font-size: 4em;
    text-align: center;
  }

  & > .go-home-button {
    cursor: pointer;
    outline: none;
    border: none;
    padding: 1em;
    margin: 1em;
    font-size: 1.5em;
    background-color: #fc0;
    border-radius: 1em;
    -webkit-transition: .1s transform linear , .1s background-color linear;
    transition: .1s transform linear , .1s background-color linear;
  }
}

@media (max-width: $toggle-menu-view-width) {
  .error-view {
    padding: 85px 3% 0;
    font-size: .75em;
  }
}

@media (max-width: 480px) {
  .error-view {
    font-size: .5em;
    & > .go-home-button {
      font-size: 2em;
      width: 100%;
    }
  }
}
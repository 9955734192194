@import "src/css/constants.scss";

.user-parcel-table-view {
  position: relative;
  padding: 25px 0;
}

@media (max-width: $toggle-menu-view-width) {
  .user-parcel-table-view {
    padding: 85px 0 25px;
  }
}
@import "src/css/constants.scss";

.auth-view {
  position: relative;
  padding: 15px 20px;
  box-sizing: border-box;

  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  & > .auth-container {
    padding: 60px;
    box-shadow: 0 0 10px 5px rgba(221, 221, 221, 1);

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 1em;

    & .invalid-restore-token {
      width: 100%;
      text-align: center;
      font-size: 2em;
    }
  }
}


@media (max-width: $toggle-menu-view-width) {
  .auth-view {
    padding: 85px 0 25px;
  }
}

@media (max-width: 650px) {
  .auth-view {
    & > .auth-container {
      width: 94%;
      margin: 10px 3% 0;
      padding: 50px 30px;
      & > div {
        width: 100%;
      }
    }
  }
}

@media (max-width: 480px) {
  .auth-view {
    & > .auth-container {
      padding: 20px;
      & > div {
        width: 100%;
      }
      & .invalid-restore-token {
        font-size: 1.5em;
      }
    }
  }
}
.notification-list {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  & > .notification-list-row {
    cursor: pointer;
    &:nth-child(odd) {
      background-color: rgb(230, 230, 230);
    }
    &:hover {
      background-color: rgba(240, 240, 240, 1);
    }
  }
}
.button {
  &:hover {
    background-color: rgb(255, 255, 0);
  }
  width: unset;

  -webkit-transition: .1s all linear;
  transition: .1s all linear;
  -webkit-appearance: none;

  border-radius: 0;

  border: none;
  outline: none;
  background: #fc0;
  padding: 10px;
  cursor: pointer;

  text-overflow: ellipsis;
  overflow: hidden;

  font-size: 1em;

  &.disabled {
    background-color: lightgray;
  }
}
.custom-input {
  position: relative;

  display: flex;
  flex-direction: column;

  & > .container {
    position: relative;

    margin: 1em 0;

    &.invalid {
      &:after {
        animation-name: validate;
        animation-duration: .3s;
        animation-timing-function: linear;

        border: none;

        content: '';

        height: 2px;
        width: 100%;

        background-color: red;

        position: absolute;
        left: 0;
        bottom: 0;

        z-index: 1;
      }
    }
    &.valid {
      &:after {
        animation-name: validate;
        animation-duration: .3s;
        animation-timing-function: linear;

        border: none;

        content: '';

        height: 2px;
        width: 100%;

        background-color: green;

        position: absolute;
        left: 0;
        bottom: 0;

        z-index: 1;
      }
    }

    & > .tip {
      height: 0;
      position: absolute;
      width: 100%;
      text-align: left;
      font-size: .6em;
      left: .25em;
      color: red;
    }
  }

    & > .label {
      color: gray;
      display: inline-block;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
      left: .25em;
      top: 0;
      width: 100%;
      text-align: left;

      &.label-start {
        transform: translateY(1.2em);
        font-size: 1em;
        position: absolute;
        transform-origin: left;
      }

      &.label-slide {
        transform: scale(.75);
        position: absolute;
        transform-origin: left;
      }
    }
}
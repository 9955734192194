.auth-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: auto;

  font-size: 1.5em;

  margin: 0;

  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  & > a {
    &:visited {
      text-decoration: none;
      color: black;
    }
    color: black;
    padding: 0 10px;
    cursor: pointer;
    text-decoration: none;
    &.bold {
      font-weight: 800;
    }
    &.sign-in {
      border-right: 3px solid black;
    }
  }
}

@media (max-width: 800px) {
  .auth-nav {
    width: 100%;
  }
}

@media (max-width: 650px) {
  .auth-nav {
    font-size: 1.5em;
    & > .sign-in {
      border-right: 2px solid black;
    }
  }
}

@media (max-width: 480px) {
  .auth-nav {
    font-size: 1em;
    & > .sign-in {
      border-right: 1px solid black;
    }
  }
}
@import "src/css/constants.scss";

.parcel-table-view {
  position: relative;
  padding: 25px 0;
}

@media (max-width: $toggle-menu-view-width) {
  .parcel-table-view {
    padding: 85px 0 25px;
  }
}

@media (max-width: 800px) {
  .parcel-table-view {
    & div.popup-content {
      width: 90%;
    }
  }
}

@media (max-width: 480px) {
  .parcel-table-view {
    & div.popup-content {
      width: 80%;
    }
  }
}
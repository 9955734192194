$busy-loader-height: 50px;

.busy-window {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  background: rgba(255, 255, 255, 0.5);

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 1;

  .loader {
    position: absolute;

    width: $busy-loader-height;
    height: $busy-loader-height;

    .lds-ring {
      div {
        border: $busy-loader-height / 15 solid;
        border-color: black transparent transparent transparent;
      }
    }
  }
}

@media (max-width: 1224px) {
  .busy-window {
    position: fixed;
  }
}
.help-message {
  -webkit-transition: .2s all ease;
  transition: .2s all ease;
  display: block;
  justify-content: center;
  padding: 1em;
  font-size: 1em;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;

  background: white;
  box-shadow: 0 0 10px 5px rgba(221, 221, 221, 1);
  word-wrap: break-word;
  z-index: 1;

  border-radius: 1em;

  &.hidden {
    display: none;
  }

  & > .help-message-triangle {
    content: '';
    position: absolute;
    background: white;
    margin: 0;
    padding: 0;
    transform: rotate(45deg);
    z-index: 2;
  }
}
.custom-select {
  position: relative;

  width: 100%;
  height: 2em;
  padding: .25em;

  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  outline: none;
  border: none;
  border-bottom: 2px solid lightgray;

  font-size: 1em;

  background: transparent;
  z-index: 1;

  cursor: pointer;

  & > .select {
    margin: auto;

    & > .select-short-view {
      display: block;

      height: 100%;
      width: 100%;
      box-sizing: border-box;

      border: none;
      outline: none;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      cursor: pointer;

      text-align: left;
    }

    & > .select-detailed-view {
      position: relative;

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      overflow: auto;

      max-height: 20em;

      box-shadow: 0 0 10px 5px rgba(221, 221, 221, 1);

      cursor: pointer;

      background: white;

      z-index: 1;

      & > .option {
        &:hover {background-color: #fc0;}

        width: 100%;
        min-height: 2em;
        padding: .5em;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;

        border-bottom: 1px solid lightgray;

        overflow: auto;

        overflow-wrap: break-word;
        flex-shrink: 0;

        z-index: 1;

        background: white;

        text-align: left;

        &.disabled {
          &:hover {background-color: lightgray;}
          background-color: lightgray;
          color: gray;
        }
        &.selected {
          &:hover {background-color: lightgray;}
          background-color: lightgray;
          color: gray;
        }
      }

      & > .no-option {
        width: 100%;
        padding: .5em;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;

        overflow: auto;

        overflow-wrap: break-word;
        flex-shrink: 0;

        z-index: 1;

        color: lightgray;

        background: white;

        text-align: left;
      }
    }
  }

  &.opened {
    z-index: 2;

    & > .chevron {
      transform: rotate(-180deg);
    }
  }

  .chevron {
    background-image: url('../../../../assets/icons/chevron.svg');
    background-repeat: no-repeat;

    position: absolute;
    top: .4em;
    right: .35em;
    width: 1.2em;
    height: 1.2em;

    transform-origin: center center;
    -webkit-transition: .1s all linear;
    transition: .1s all linear;
  }
}

@media (max-width: 1224px) {
  .custom-select {
    & > .select{
      & > .select-detailed-view {
        max-height: 15em;
      }
    }
  }
}
.row-short-view {
  &:hover {
    color: #c80000;
  }
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 95%;
  height: 50px;

  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  & > div {
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 1em 0;
  }

  .status {
    padding: 0.75em 0;

    .status-img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

@media (max-width: 1224px) {
  .row-short-view {
    flex-direction: column;
    align-items: flex-end;
    height: auto;

    width: 100%;
    font-size: 1em;

    padding-top: 2em;

    .request_id,
    .sender,
    .receiver,
    .item,
    .agreed,
    .from_address,
    .to_address,
    .location {
      padding: 0.125em 1em;

      -webkit-box-sizing: border-box;
      box-sizing: border-box;

      text-align: left;
    }

    .status {
      &::before {
        content: "Статус:";
        margin-right: 5px;
        font-weight: 700;
        position: absolute;

        left: 1em;
      }

      height: 2em;
      padding: 0.5em 1em;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;

      display: flex;
      align-items: center;

      position: relative;

      .status-img {
        width: auto;
        max-height: 1.5em;
        object-fit: contain;
        position: absolute;
        left: 5em;
      }
    }

    .request_id::before {
      content: "Номер посылки:";
      margin-right: 5px;
      font-weight: 700;
    }

    .sender::before {
      content: "Отправитель:";
      margin-right: 5px;
      font-weight: 700;
    }

    .receiver::before {
      content: "Получатель:";
      margin-right: 5px;
      font-weight: 700;
    }

    .item::before {
      content: "Описание посылки:";
      margin-right: 5px;
      font-weight: 700;
    }

    .agreed::before {
      content: "Согласовано с:";
      margin-right: 5px;
      font-weight: 700;
    }

    .from_address::before {
      content: "Точка отправки:";
      margin-right: 5px;
      font-weight: 700;
    }

    .to_address::before {
      content: "Точка получения:";
      margin-right: 5px;
      font-weight: 700;
    }

    .location::before {
      content: "Местоположение:";
      margin-right: 5px;
      font-weight: 700;
    }
  }
}

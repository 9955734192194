.location-table-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  & > .location_id,
  & > .location_name,
  & > .location_address,
  & > .location_status {
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 1em 0;
  }
}

@media (max-width: 800px) {
  .location-table-row {
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;
    box-shadow: 0 0 5px 3px rgba(221, 221, 221, 1);
    border-radius: 2em;
    padding: 2em 0 0 0;
    margin: 10px auto;
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;

    .location_id::before {
      content: 'Номер:';
      margin-right: 5px;
      font-weight: 700;
    }

    .location_name::before {
      content: 'Название:';
      margin-right: 5px;
      font-weight: 700;
    }

    .location_address::before {
      content: 'Адрес:';
      margin-right: 5px;
      font-weight: 700;
    }

    .location_status::before {
      content: 'Статус:';
      margin-right: 5px;
      font-weight: 700;
    }
  }
}
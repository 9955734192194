@import "src/css/constants.scss";

.parcel-page-view {
  padding: 25px;
  & > .parcel-page-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 20px;
    box-shadow: 0 0 10px 5px rgba(221, 221, 221, 1);
    padding: 50px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 1em;

    width: 80%;
    margin: auto;

    & > .parcel-page-loader {
      width: 100px;
      height: 100px;

      margin: auto;
    }

    & > .page-title-send-list-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      margin-bottom: 20px;
      width: 100%;

      & > .page-title {
        font-size: 1.5em;
        font-weight: 700;
        text-align: left;
      }
      & > .print-send-list {
        cursor: pointer;
        border-bottom: 1px dashed black;
      }
    }

    & > .status-bar-main-info-container {
      width: 100%;

      & > .status-edit-tool {
        width: 100%;
        font-size: 16px;
        margin-bottom: 20px;

        & > .select-button-container {
          & > .custom-select {
            width: 75%;
          }
          & > .button-popup-container {
            width: 25%;
            & > button {
              width: 100%;
            }
          }
          .status-edit-loader {
            width: 25%;
          }
        }
      }

      & > .main-info {
        height: unset;
        max-width: max-content;
        margin: 0 0 10px 0;
      }

      & > .edit-button-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px 0 0 0;

        & > .button-popup-container {
          & > button {
            border-radius: 2em;
            padding: .5em 2em;
          }
        }
      }
    }

    & > .location-history-container {
      font-size: 16px;
      width: 100%;

      & > .location-history-title {
        font-size: 1.5em;
        font-weight: 700;
        width: 100%;
        margin: 20px 0 10px 0;
      }

      & > .location-add-tool {
        & > .custom-select {
          width: 75%;
        }
        & > .button-popup-container {
          width: 25%;
          & > button {
            width: 100%;
          }
        }
        .add-location-loader {
          width: 25%;
        }
      }

      & > .location-history-table {
        & > .content {
          & > div:nth-child(odd).location-history-row {
            background-color: rgb(230, 230, 230);
          }
        }
      }
    }
  }
}

@media (max-width: $toggle-menu-view-width) {
  .parcel-page-view {
    padding: 85px 20px 0;

    & > .parcel-page-container {
      font-size: 16px;
      padding: 20px;
      width: 100%;
      & > .page-title-send-list-container {
        & > .print-send-list {
          display: none;
        }
      }
      & > .status-bar-main-info-container {
        & > .status-edit-tool {
          font-size: 14px;
        }
      }
      & > .location-history-container {
        font-size: 14px;
      }
    }
  }
}

@media (max-width: 480px) {
  .parcel-page-view {
    padding: 85px 20px 0;

    & > .parcel-page-container {
      font-size: 12px;
      & > .page-title-send-list-container {
        margin-bottom: 10px;
      }
      & > .status-bar-main-info-container {
        & > .status-edit-tool {
          font-size: 10px;
          margin-bottom: 10px;
          & > .title {
            margin-bottom: 5px;
          }
        }
        & > .main-info {
          margin: 0 0 5px 0;
        }
        & > .edit-button-container {
          margin: 5px 0 0 0;
        }
      }
      & > .location-history-container {
        font-size: 10px;
        & > .location-history-title {
          margin: 10px 0 5px 0;
        }
        & > .location-add-tool {
          margin-bottom: 5px;
        }
      }
    }
  }
}
.change-list {
  overflow-y: auto;
  max-height: 85vh;
  padding: 0 25px;

  box-sizing: border-box;

  & > .change-list-row {
    padding: 1em;
    box-sizing: border-box;
    &:nth-child(odd) {
      background-color: rgb(230, 230, 230);
    }
    & > a {
      word-wrap: break-word;
    }
  }

  & > .button-popup-container {
    width: 15%;
    margin: 10px auto 0;
    & > .load-more {
      width: 100%;
      margin: 0;
      font-size: 1em;
      font-weight: 400;
      padding: .75em 1.5em;
      height: unset;
      border-radius: 1.5em;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }
  }
}

@media (max-width: 1224px) {
  .change-list {
    & > .button-popup-container {
      width: 30%;
      margin: 10px auto 0;
    }
  }
}

@media (max-width: 800px) {
  .change-list {
    & > .button-popup-container {
      width: 100%;
      margin: 10px 0 0;
    }
  }
}

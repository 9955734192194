$loader-height: 50px;

.location-history-table {
  display: flex;
  flex-direction: column;

  width: 100%;

  &.no-delete {
    & div {
      justify-content: space-between;
    }
  }

  & > .titles {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    padding: 0 30px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    font-weight: 700;

    & > div {
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      padding: 0.5em;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }
  }

  & > .content {
    overflow: auto;
    height: auto;
    max-height: 380px;

    padding: 20px 30px;
    border-radius: 20px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    .no-history {
      width: 100%;
      height: 100%;
      font-size: 1.25em;
      padding: 3em 0;
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
    }

    .loader {
      margin: 3em auto;
      width: $loader-height;
      height: $loader-height;
      .lds-ring {
        div {
          border: $loader-height / 15 solid;
          border-color: black transparent transparent transparent;
        }
      }
    }
  }

  .location_name {
    width: 20%;
  }

  .location_address {
    width: 50%;
  }

  .location_time {
    width: 25%;
  }
}

@media (max-width: 1224px) {
  .location-history-table {
    justify-content: flex-start;
    height: auto;

    .titles {
      width: 100%;
      padding: 0 20px;
    }

    .content {
      max-height: 400px;
      padding: 10px 20px;
    }
  }
}

@media (max-width: 480px) {
  .content {
    max-height: 244px;
  }
}

.search-tool-container {
  display: flex;

  & > .search-tool {
    position: relative;

    display: flex;
    flex-direction: row;
    align-items: center;

    padding: 0 15px;
    margin-bottom: 10px;

    & > .search-tool-label {
      font-weight: bold;
    }

    & > .search-tool-input {
      &:focus,
      &:hover {
        outline: none;
        box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
      }
      max-width: 500px;
      width: 50vw;
      height: unset;
      font-size: 1em;
      padding: .75em 45px .75em 1em;
      border: 1px solid #dfe1e5;
      border-radius: 2em;
      outline: none;

      box-shadow: none;

      margin: 0 10px;
    }

    & > .search-tool-button {
      background-image: url('../../assets/icons/search.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;

      position: absolute;
      right: 3.8em;

      width: 20px;
      height: 20px;

      cursor: pointer;

      z-index: 1;
    }

    & > .search-tool-hint {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      & > .search-tool-hint-item {
        &:hover + .search-tool-hint-text {
          display: block;
        }
        background-image: url('../../assets/icons/hint.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;

        width: 20px;
        height: 20px;

        cursor: pointer;
      }

      & > .search-tool-hint-text {
        &::after {
          content: '';
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 10px solid white;

          position: absolute;

          left: 90px;
          top: -9px;
        }

        display: none;

        position: absolute;
        top: 30px;
        left: -90px;

        width: 200px;
        height: auto;

        background-color: white;
        box-shadow: 0 0 10px 5px rgba(221, 221, 221, 1);

        padding: 1em;

        -webkit-box-sizing: border-box;
        box-sizing: border-box;

        z-index: 1;
        word-wrap: break-word;

        border-radius: 1em;
      }

    }
  }
}

@media (max-width: 800px) {
  .search-tool-container {
    & > .search-tool {
      width: 100%;
      & > .search-tool-label {
        display: none;
      }

      & > .search-tool-input {
        max-width: unset;
        margin-left: 0;
        width: 100%;
        font-size: 1.2em;
      }

      & > .search-tool-hint {
        & > .search-tool-hint-text {
          &::after {
            left: 168px;
          }
          top: 25px;
          left: -170px;

          width: 200px;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .search-tool-container {
    & > .search-tool {
      & > .search-tool-input {
        font-size: .9em;
      }
    }
  }
}
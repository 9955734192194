.parcel-row {
  font-size: 1em;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;

  width: 100%;

  transform-origin: center center;
  -webkit-transition: all .1s linear;
  transition: all .1s linear;

  /* box-sizing: border-box; */

  .show-detailed-view {
    &:hover {
      background-color: rgba(240, 240, 240, 1);
    }

    cursor: pointer;
    display: flex;
    align-items: center;
    min-width: 5%;
    max-width: 5%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    & > img {
      transform-origin: center center;
      -webkit-transition: all .1s linear;
      transition: all .1s linear;

      object-fit: contain;
      height: 30px;
      width: 30px;
      border-radius: 15px;
      padding: 10px;
      margin: auto;

      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }

    & > div {
      display: none;
    }
  }

  &.opened {
    & > .show-detailed-view {
      margin-right: 15px;

      & > img {
        transform: rotate(-180deg);
      }
    }
  }
}

@media (max-width: 1224px) {
  .parcel-row {
    &:nth-child(even) {
      margin: 10px 15px 10px 0;
    }
    &:nth-child(odd) {
      margin: 10px 0 10px 15px;
    }

    height: 100%;
    width: 47%;

    flex-direction: column-reverse;

    position: relative;

    box-shadow: 0 0 5px 3px rgba(221, 221, 221, 1);
    border-radius: 2em;
    overflow: hidden;

    &.opened {
      min-height: 100px;
      max-height: 1400px;
      height: unset;

      .show-detailed-view {
        margin: 0;
        & > img {
          padding: 0 7px 2px 7px;
        }
      }
    }
    .show-detailed-view {
      min-width: 100%;
      height: 3em;
      justify-content: center;
      padding: .5em;

      & > div {
        display: block;
        font-size: 1.25em;
        font-weight: 400;
      }

      & > img {
        border-radius: 0;
        margin: 0;

        padding: 4px 7px 0 7px;
      }
    }
  }
}

@media (max-width: 800px) {
  .parcel-row {
    &:nth-child(odd),
    &:nth-child(even) {
      margin: 10px 15px;
    }
    width: 100%;
  }
}
.notification-control-box {
  display: flex;
  flex-direction: column;
  padding: 25px;

  box-sizing: border-box;
  & > .location-info-container {
    display: flex;
    flex-direction: column;
    & > .location-info-label {
      font-size: 1.5em;
      color: black;
      font-weight: 700;
      margin-bottom: 10px;
      max-width: 90%;
    }
    & > .location-info-content {
      margin-bottom: 10px;
      & > .location-info-row {
        display: flex;
        flex-direction: row;
        & > .label {
          color: gray;
          margin-right: 5px;
        }
      }
    }
  }

  & > .close-control-box {
    position: absolute;
    right: 25px;
    top: 25px;
    background-image: url("../../../assets/icons/cross.svg");
    background-size: contain;
    width: 2em;
    height: 2em;
    cursor: pointer;
  }
}
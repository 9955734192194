.location-history-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  & > div {
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    padding: .5em;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  & > .delete-location-button {
    &:hover {
      background-color: rgba(240, 240, 240, 1);
    }

    background-image: url('../../../assets/icons/delete.svg');
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: 1.1em;
    background-position: center;

    cursor: pointer;
    width: 5%;
  }
}

@media (max-width: 1224px) {
  .location-history-row {
    & > .delete-location-buttons {
      background-size: 1em;
    }
  }
}

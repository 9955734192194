.user-table-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  & > .user_id,
  & > .user_email,
  & > .user_role,
  & > .user_status {
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 1em 0;
  }
}

@media (max-width: 800px) {
  .user-table-row {
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;
    box-shadow: 0 0 5px 3px rgba(221, 221, 221, 1);
    border-radius: 2em;
    padding: 2em 0 0 0;
    margin: 10px auto;
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;

    .user_id::before {
      content: 'Номер:';
      margin-right: 5px;
      font-weight: 700;
    }

    .user_email::before {
      content: 'Почта:';
      margin-right: 5px;
      font-weight: 700;
    }

    .user_role::before {
      content: 'Роль:';
      margin-right: 5px;
      font-weight: 700;
    }

    .user_status::before {
      content: 'Статус:';
      margin-right: 5px;
      font-weight: 700;
    }
  }
}
input {
  &:focus {
    outline: none;
    border-bottom: 2px solid #fc0;
  }
  width: 100%;
  height: 2em;

  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  outline: none;
  border: none;
  border-bottom: 2px solid lightgray;

  padding: .25em;

  font-size: 1em;

  position: relative;
  background: transparent;
  z-index: 1;

  @keyframes validate{
    from {
      max-width: 0;
    }
    to {
      max-width: 100%;
    }
  }
}
$submit-height: 2.75em;

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 650px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  box-shadow: 0 0 10px 5px rgba(221, 221, 221, 1);
  margin: 10px 20px 0 20px;
  padding: 30px;
  font-size: 16px;
  border-radius: 1em;

  & > fieldset{
    border: none;
    width: 100%;
    min-inline-size: unset;

    & > legend {
      font-weight: 700;
      font-size: 1em;
      text-align: center;
      width: 100%;
    }

    & .custom-select {
      & > .select {
        & > .select-short-view {
          padding-right: 1.5em;
        }
      }
    }
  }

  .button-popup-container {
    .submit{
      height: $submit-height;
      padding: .75em 2.5em;
      border-radius: 1.5em;
    }
  }

  .loader {
    width: $submit-height;
    height: $submit-height;
    .lds-ring {
      div {
        border: $submit-height / 15 solid;
        border-color: black transparent transparent transparent;
      }
    }
  }
}

@media (max-width: 690px) {
  form {
    width: 94%;
    margin: 10px 3% 0;
  }
}

@media (max-width: 480px) {
  form {
    font-size: 12px;
    padding: 20px;

    .button-popup-container {
      width: 100%;
      .submit {
        width: 100%;
      }
    }
  }
}
.row-detailed-view {
  padding: 50px 30px 50px 0;
  width: 95%;
  height: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  position: relative;

  & > .status-bar-main-info-container {
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 20px 30px;
    border-radius: 20px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    & > .main-info {
      margin: 2em 0;
    }

    & > .button-popup-container {
      & > .button {
        border-radius: 20px;
        padding: .5em;
        width: 100%;
      }
    }
  }

  & > .location-history-container {
    width: 68%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .location-history-title {
      font-size: 1.5em;
      font-weight: 700;
      width: 100%;
      margin-bottom: 10px;
    }
  }

  .parcel-page-link-container {
    position: absolute;
    right: 40px;
    top: 20px;
  }
}

.row-detailed-view .edit-form-open-loader {
  transform: scale(.6);
  min-height: 39px;
  max-height: 39px;
}

.row-detailed-view .send-list {
  position: absolute;
  right: 40px;
  top: 65px;
  cursor: pointer;
  border-bottom: 1px dashed black;
}


@media (max-width: 1224px) {
  .row-detailed-view {
    padding: 100px 10px 0 10px;
    min-width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;

    & > .status-bar-main-info-container {
      width: 100%;
      height: unset;
    }

    & > .location-history-container {
      width: 100%;
      height: unset;
      margin: 2em 0;
    }

    .parcel-page-link-container {
      right: 10px;
      top: 20px;
    }
  }

  .row-detailed-view .edit-form-open-loader {
    transform: scale(.4);
    min-height: 34px;
    max-height: 34px;
  }
}

@media (max-width: 800px) {
  .row-detailed-view {
    padding: 75px 10px 0 10px;
  }
}
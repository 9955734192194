@import "src/css/constants.scss";

.location-table-view {
  padding: 25px 20px 0;
  position: relative;
  & > .location-table-container {
    min-width: 720px;
    width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    box-shadow: 0 0 10px 5px rgba(221, 221, 221, 1);
    padding: 40px;
    border-radius: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;

    .location_id {
      width: 12%;
    }
    .location_name {
      width: 22%;
    }
    .location_address {
      width: 30%;
    }
    .location_status {
      width: 13%;
    }

    & > .location-table {
      width: 100%;
      max-height: 600px;
      overflow: auto;
      margin-bottom: 20px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      & > .location-table-row:nth-child(odd) {
        background-color: rgb(230, 230, 230);
      }
    }

    .button-popup-container {
      & > .add-location {
        padding: .75em 2.5em;
        border-radius: 1.5em;
      }
    }
  }
}

@media (max-width: $toggle-menu-view-width) {
  .location-table-view {
    padding: 85px 20px 25px;
  }
}

@media (max-width: 800px) {
  .location-table-view {
    padding: 85px 0 25px;
    & > .location-table-container {
      min-width: unset;
      box-shadow: none;
      padding: 0;

      .location_id,
      .location_name,
      .location_address,
      .location_status {
        box-sizing: border-box;
        width: 100%;
        padding: .125em 1em;
        text-align: left;
      }

      & > .location-table {
        max-height: unset;
        padding: 0 20px;
        & > .location-table-row:nth-child(odd) {
          background-color: white;
        }
      }

      .button-popup-container {
        width: 95%;
        & > .add-location {
          width: 100%;
          margin: auto;
        }
      }
    }
    & div.popup-content {
      width: 90%;
    }
  }
}

@media (max-width: 480px) {
  .location-table-view {
    font-size: .75em;
    & div.popup-content {
      width: 80%;
    }
  }
}
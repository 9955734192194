.edit-button {
  &:hover {
    background-color: #fc0;
  }
  -webkit-transition: .1s all linear;
  transition: .1s all linear;
  cursor: pointer;
  padding: 1em;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 23%;
  box-sizing: border-box;

  & > .edit-button-img {
    height: 0.75em;
    width: 0.75em;
    margin-right: 5px;
  }
}

@media (max-width: 800px) {
  .edit-button {
    width: 100%;
    padding: .5em;
    margin-top: 1em;
    justify-content: center;
    font-size: 1.2em;
    background-color: rgb(230, 230, 230);
  }
}
@import "src/css/constants.scss";

.header {
  border-bottom: 1px solid gray;
  height: 50px;
  display: flex;
  align-content: center;
  justify-content: space-between;
  padding: 5px 10px;

  .logo{
    cursor: pointer;
    height: 100%;

    & > img {
      width: auto;
      height: 100%;
      object-fit: contain;
    }
  }

  .open-menu-button {
    height: 100%;
    width: 100%;
    display: none;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      height: 25px;
      width: 25px;
      margin-right: 25px;
      & > div {
        &::before {
          content: '';
          height: 5px;
          width: 5px;
          border-radius: 3px;
          position: absolute;
          left: -2.5px;

          background: black;
        }
        &::after {
          content: '';
          height: 5px;
          width: 5px;
          border-radius: 3px;
          position: absolute;
          right: -2.5px;

          background: black;
        }

        height: 20%;
        width: 100%;
        background-color: black;

        position: relative;

        transition: all .2s linear;
      }
    }
    &.opened {
      & > div {
        & > div {
          &:first-child {
            display: block;
            transform-origin: left;
            transform: rotate(45deg) translate(2.5px, -1.5px);
          }
          &:last-child {
            display: block;
            transform-origin: left;
            transform: rotate(-45deg) translate(2.5px, 1.5px);
          }

          display: none;
        }
      }
    }
  }
  .menu {
    display: flex;
    flex-direction: row;
    align-items: stretch;

    .main-page,
    .parcels-page,
    .my-parcels-page,
    .locations-page,
    .users-page,
    .notifications-page,
    .change-list-page,
    .auth-page {
      &:visited{color: black}
      &.selected::after {
        content: '';
        background-color: #fc0;
        height: 3px;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: .5em;
      }
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      padding: 1em 0;
      margin: 0 1em;
      box-sizing: border-box;

      font-size: 1em;
      font-weight: 700;

      position: relative;

      cursor: pointer;

      text-decoration: none;
      color: black;

      outline: none;
    }

    .button-popup-container {
      display: flex;
      align-items: center;
      justify-content: center;
      & > .logout {
        font-size: 1em;
        font-weight: 700;
        margin: auto 0;
        height: unset;
      }
    }
  }
}


@media (max-width: $toggle-menu-view-width) {
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  height: 60px;

  padding: 8px 0 4px 8px;
  margin: 0;

  border: none;

  box-shadow: 0 0 4px 4px #c1c1c1;

  box-sizing: border-box;
  z-index: 3;

  background-color: white;

  .open-menu-button {
    display: flex;
    &.opened + .menu {
      display: flex;
    }
  }
  .menu {
    font-size: 1.5em;

    padding: .5em 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    animation-name: show-menu;
    animation-duration: .2s;

    background: white;

    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    position: fixed ;
    right: 0;
    left: 0;
    top: 60px;
    bottom: 0;

    box-shadow: inset 0 8px 4px -4px #c1c1c1;

    display: none;
    overflow: hidden;

    .main-page,
    .my-parcels-page,
    .parcels-page,
    .locations-page,
    .users-page,
    .notifications-page,
    .change-list-page,
    .auth-page {
      &:after {
        content: '';
        background-color: #ebebeb;
        width: 5px;
        height: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
      }

      padding: 1em 0 1em 1em;
      margin: 0;

      text-align: left;

      height: unset;
      width: 100%;

      border-top: 1px solid #ebebeb;

      &.selected {
        &:after {
          content: '';
          background-color: #fc0;
          width: 5px;
          height: 100%;
          position: absolute;
          bottom: 0;
        }
      }
    }

    .main-page{
      border: none;
    }

    .button-popup-container {
      width: 100%;
      .logout {
        &:hover {
          background-color: #fc0;
        }
        background-color: #ebebeb;
        width: 100%;
        margin-top: 0;
      }
    }
  }
}

  @keyframes show-menu {
    from {max-height: 0;}
    to {max-height: 100%;}
  }
}

@media (max-width: 800px) and (orientation: landscape) {
  .header {
    .menu {
      font-size: 1em;
    }
  }
}

@media (max-width: 480px) {
  .header {
    .menu {
      font-size: 1em;
    }
  }
}
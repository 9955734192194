@import "src/css/constants.scss";

.change-list-view {
  position: relative;
  padding: 25px 0;
}

@media (max-width: $toggle-menu-view-width) {
  .change-list-view {
    padding: 85px 20px 25px;
  }
}

@media (max-width: $toggle-menu-view-width) {
  .change-list-view {
    padding: 85px 0 25px;

    max-height: unset;
    & > .change-list {
      width: 100%;
      overflow-y: visible;
      max-height: unset;
    }
  }
}

@media (max-width: 480px) {
  .change-list-view {
    font-size: 12px;
  }
}
div.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: rgba(0,0,0,0.5);

  overflow: hidden;

  display: flex;
  align-items: center;
  -ms-flex-align: center;
  justify-content: center;

  z-index: 5;

  & > .popup-content {
    width: auto;
    height: auto;

    padding: 20px;

    border-radius: 10px;

    background-color: white;

    box-shadow: 0 0 10px #000;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h1 {
      text-align: center;
    }

    & * {
      box-shadow: none;
    }
  }
}

@media (max-width: 800px) {
  .popup {
    .popup-content {
      width: 90%;
    }
  }
}

@media (max-width: 480px) {
  .popup {
    .popup-content {
      width: 85%;

      h1 {
        font-size: 1.5em;
      }
    }
  }
}
.question-container {
  font-size: 16px;
  & > .question-text {
    font-weight: 400;
    text-align: center;
    margin: 0 .5em .5em .5em;
  }
  & > .button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    .button-popup-container {
      width: 35%;
      margin: 0 .5em;
      & > button {
        width: 100%;
        font-size: 1em;
        padding: .5em;
        border-radius: 1em;
      }
    }
  }
}

@media (max-width: 480px) {
  .question-container {
    & > .button-container {
      font-size: .75em;
    }
  }
}
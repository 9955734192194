.location-table-titles {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  width: 100%;

  & > div {
    text-align: center;
    font-weight: 700;
    word-wrap: break-word;
    padding: 1em 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
}

@media (max-width: 800px) {
  .location-table-titles {
    display: none;
  }
}
.parcel-page-link-container {
  &:hover {
    background-color: rgb(255, 255, 0);
  }
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  color: black;
  background-color: #fc0;
  border-radius: 17.5px;
  padding: 5px 15px;

  box-sizing: border-box;

  & > .parcel-page-link-img {
    min-height: 25px;
    min-width: 25px;
    max-height: 25px;
    max-width: 25px;
    margin-right: 5px;
    content: '';
    background-color: transparent;
    background-image: url('../../assets/icons/newwindow.png');
    background-size: contain;
    background-repeat: no-repeat;
  }
}
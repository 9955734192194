@font-face {
    font-family: "Renault";
    src: url("./assets/fonts/RenaultLifeCyWebWeb-Bold.eot");
    /* IE9 */
    src: url("./assets/fonts/RenaultLifeCyWebWeb-Bold.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/RenaultLifeCyWebWeb-Bold.woff") format("woff");
    /* Modern Browsers */
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: "Renault";
    src: url("./assets/fonts/RenaultLifeCyWebWeb-Regular.eot");
    /* IE9 */
    src: url("./assets/fonts/RenaultLifeCyWebWeb-Regular.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/RenaultLifeCyWebWeb-Regular.woff") format("woff");
    /* Modern Browsers */
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: "Renault";
    src: url("./assets/fonts/RenaultLifeCyWebWeb-Light.eot");
    /* IE9 */
    src: url("./assets/fonts/RenaultLifeCyWebWeb-Light.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/RenaultLifeCyWebWeb-Light.woff") format("woff");
    /* Modern Browsers */
    font-style: normal;
    font-weight: 100;
}

body * {
    font-family: Renault, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

body {
    margin: 0;
    font-family: Renault, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.bold {
    font-weight: 700;
}

.normal {
    font-weight: 400;
}

h1.forbidden {
    width: 100%;
    text-align: center;
    font-size: 3em;
}

input {
    -webkit-border-radius: 0;
    border-radius: 0;
}
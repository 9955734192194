.notification-list-row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  width: 100%;
  min-height: 80px;
  padding: 10px 20px;

  box-sizing: border-box;

  & > .location {
    margin: 5px 0;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-style: italic;
    font-weight: 700;
  }

  & > .users {
    margin: 5px 0;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
form.sign-in-form {
  box-shadow: none;

  margin: 0;
  padding: 0;
  width: 500px;

  & > fieldset {
    & > .restore-password {
      &:visited {color: blue;}
      font-size: .75em;
    }
  }
}

@media (max-width: 650px) {
  form.sign-in-form {
    width: 100%;
  }
}
$add-user-height: 2em;

.user-add-tool {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  height: $add-user-height;
  margin-bottom: 10px;

  & > .custom-select {
    width: 50%;
    border-top-left-radius: 1.5em;
    border-bottom-left-radius: 1.5em;
    padding-left: 0;

    background: white;

    border: 1px solid #fc0;

    & > .select {
      & > .select-short-view {
        border-top-left-radius: 1.5em;
        border-bottom-left-radius: 1.5em;
        padding: 0 1.5em 0 1em;
      }
      & > .select-detailed-view {
        border-top-left-radius: 0.75em;
        border-bottom-left-radius: 0.75em;

        & > .option {
          padding: .25em .25em .25em 1em;
        }
      }
    }

    &.opened {
      padding: 0;
    }
  }

  .button-popup-container {
    height: 100%;
    width: 15%;
    & > button {
      width: 100%;
      height: 100%;

      padding: .25em;

      border-top-right-radius: 1.5em;
      border-bottom-right-radius: 1.5em;
    }
  }

  .add-user-loader {
    width: 15%;
    height: 100%;

    border-top-right-radius: 1.5em;
    border-bottom-right-radius: 1.5em;

    background-color: lightgray;

    .loader {
      width: $add-user-height;
      height: $add-user-height;

      margin: auto;
      .lds-ring {
        div {
          border: $add-user-height / 15 solid;
          border-color: black transparent transparent transparent;
        }
      }
    }
  }
}

@media (max-width: 1224px) {
  .user-add-tool {
    & > .custom-select {
      width: 70%;
    }

    .button-popup-container {
      width: 30%;
    }
    .add-user-loader {
      width: 30%;
    }
  }
}
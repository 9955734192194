.user-parcel-table-container {
  margin: 0 15px;

  .request_id,
  .sender,
  .receiver,
  .status,
  .item,
  .agreed,
  .to_address,
  .from_address,
  .location {
    width: 10%;
  }

  .parcel-page-link-container {
    width: 9%;
  }

  .user-parcel-table {
    max-height: 75vh;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    & > h1 {
      text-align: center;
      width: 100%;
      margin-right: 15px;
      margin-left: 15px;
    }

    & > div:nth-child(odd) {
      background-color: rgb(230, 230, 230);
    }

    & > div:nth-child(even) {
      background-color: white;
    }

    & > .button-popup-container {
      min-width: 15%;
      margin: 10px 42.5% 0;
      & > .load-more {
        width: 100%;
        margin: 0;
        font-size: 1em;
        font-weight: 400;
        padding: 0.75em 1.5em;
        height: unset;
        border-radius: 1.5em;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
      }
    }
  }
}

@media (max-width: 1224px) {
  .user-parcel-table-container {
    margin-left: 0;
    .request_id,
    .sender,
    .receiver,
    .item,
    .agreed,
    .from_address,
    .to_address,
    .location,
    .status {
      width: 100%;
    }

    .parcel-page-link-container {
      margin-top: 0.5em;
      width: unset;
    }

    .user-parcel-table {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      max-height: 100%;

      overflow: visible;

      & > div:nth-child(odd),
      & > div:nth-child(even) {
        background-color: white;
      }

      & > .button-popup-container {
        width: 30%;
        margin: 10px 35% 0;
      }
    }
  }
}

@media (max-width: 800px) {
  .user-parcel-table-container {
    & > .user-parcel-table {
      & > .button-popup-container {
        width: 100%;
        margin: 10px 15px 0;
      }
    }
  }
}

@media (max-width: 480px) {
  .user-parcel-table-container {
    & > .user-parcel-table {
      font-size: 0.75em;
    }
  }
}

.main-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 65%;

  & > div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    & > div {
      display: block;
      width: auto;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }

    & > .label {
      color: gray;
      margin: 0 5px 0 0;
    }
  }
}

@media (max-width: 1224px) {
  .main-info {
    height: unset;
    margin: 2em 0;
  }
}
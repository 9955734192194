@import "src/css/constants.scss";

.main {
  position: relative;
  padding: 15px 20px;
  box-sizing: border-box;
  form {
    margin: auto;
  }

  & .new-parcel-response-button {
    padding: 0.5em 1em;
    border-radius: 2em;
  }
}

@media (max-width: $toggle-menu-view-width) {
  .main {
    padding: 85px 0 25px;
  }
}
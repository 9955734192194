.new-parcel-response-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 600px;
  margin: 0 auto;

  & > div {
    font-size: 1.5em;
    margin: 0 0 1em;

    font-weight: 100;

    text-align: center;
    & > h5 {
      font-size: 1.25em;
      margin: .5em 0;
      text-align: center;
      font-weight: 600;
    }
  }
  & > .button {
    font-size: 1.25em;

    border-radius: 2em;
    margin: 0.67em 0;
    padding: .75em 2em;
  }
}

@media (max-width: 480px) {
  .new-parcel-response-container {
    font-size: 12px;
  }
}
.error-container {
  .error {
    font-weight: 400;
    padding: 20px;
    text-align: center;
  }
}

@media (max-width: 480px) {
  .error-container {
    .error {
      font-size: 1.2em;
    }
  }
}
@import "src/css/constants.scss";

.notification-control-view {
  position: relative;
  padding: 25px 0;

  & > div {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    max-height: 85vh;
    & > .notification-list {
      width: 100%;
      overflow-y: auto;
    }

    & > .notification-control-box {
      width: 70%;

      & > .notification-user-table-label {
        font-size: 1.5em;
        margin-bottom: 10px;
        color: black;
        font-weight: 700;
      }
    }

    &.extended {
      & > .notification-list {
        width: 30%;
      }
    }
  }
}

@media (max-width: $toggle-menu-view-width) {
  .notification-control-view {
    padding: 85px 20px 25px;
  }
}

@media (max-width: $toggle-menu-view-width) {
  .notification-control-view {
    padding: 85px 0 25px;

    max-height: unset;

    & > div {
      & > .notification-list {
        width: 100%;
        overflow-y: visible;
      }

      & > .notification-control-box {
        position: fixed;
        width: 100%;
        padding-top: 85px;
        top: 0;
        left: 0;
        bottom: 0;
        background-color: white;

        & > .close-control-box {
          right: 25px;
          top: 85px;
          width: 1.5em;
          height: 1.5em;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .notification-control-view {
    font-size: 12px;
  }
}
.notification-user-table {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  & > .notification-user-table-row {
    &:nth-child(odd) {
      background-color: rgb(230, 230, 230);
    }
  }

  & > .no-users {
    width: 100%;
    height: 100%;
    font-size: 1.25em;
    margin: 3em 0;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.parcel-table-container {
  .from_address,
  .to_address,
  .request_id,
  .sender,
  .receiver,
  .item,
  .agreed,
  .status {
    width: 10%;
  }

  .location {
    width: 15%;
  }

  .parcel-table {
    max-height: 75vh;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    & > h1 {
      text-align: center;
      width: 100%;
      margin-right: 15px;
      margin-left: 15px;
    }

    & > div:nth-child(odd) {
      background-color: rgb(230, 230, 230);
      & > .show-detailed-view {
        border-right: 1px solid white;
        & > img {
          background-color: white;
        }
      }
      & > .row-detailed-view {
        & > .status-bar-main-info-container {
          background-color: white;
        }
        & > .location-history-container {
          & > .location-history-table {
            & > .content {
              background-color: white;

              & > .location-history-row:nth-child(odd) {
                background-color: rgb(230, 230, 230);
              }
            }
          }
        }
      }
    }

    & > div:nth-child(even) {
      background-color: white;
      & > .show-detailed-view {
        border-right: 1px solid rgb(230, 230, 230);
        & > img {
          background-color: rgb(230, 230, 230);
        }
      }
      & > .row-detailed-view {
        & > .status-bar-main-info-container {
          background-color: rgb(230, 230, 230);
        }
        & > .location-history-container {
          & > .location-history-table {
            & > .content {
              background-color: rgb(230, 230, 230);
              & > .location-history-row:nth-child(odd) {
                background-color: white;
              }
            }
          }
        }
      }
    }

    & > .button-popup-container {
      min-width: 15%;
      margin: 10px 42.5% 0;
      & > .load-more {
        width: 100%;
        margin: 0;
        font-size: 1em;
        font-weight: 400;
        padding: 0.75em 1.5em;
        height: unset;
        border-radius: 1.5em;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
      }
    }
  }
}

@media (max-width: 1224px) {
  .parcel-table-container {
    .request_id,
    .sender,
    .receiver,
    .item,
    .agreed,
    .from_address,
    .to_address,
    .location,
    .status {
      width: 100%;
    }

    .parcel-table {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      max-height: 100%;

      overflow: visible;

      & > div:nth-child(odd),
      & > div:nth-child(even) {
        background-color: white;
        & > .show-detailed-view {
          border: none;
          background-color: rgb(230, 230, 230);
          & > img {
            background-color: rgb(230, 230, 230);
          }
        }
        & > .row-detailed-view {
          & > .status-bar-main-info-container {
            background-color: rgb(230, 230, 230);
          }
          & > .location-history-container {
            & > .location-history-table {
              & > .content {
                background-color: rgb(230, 230, 230);
                & > .location-history-row:nth-child(odd) {
                  background-color: rgb(230, 230, 230);
                }
                & > .location-history-row:nth-child(even) {
                  background-color: white;
                }
              }
            }
          }
        }
      }

      & > .button-popup-container {
        width: 30%;
        margin: 10px 35% 0;
      }
    }
  }
}

@media (max-width: 800px) {
  .parcel-table-container {
    & > .parcel-table {
      & > .button-popup-container {
        width: 100%;
        margin: 10px 15px 0;
      }
    }
  }
}

@media (max-width: 480px) {
  .parcel-table-container {
    & > .parcel-table {
      font-size: 0.75em;
    }
  }
}

.parcel-table-titles-container {
  padding: 0 15px 15px 5%;
  font-weight: 700;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  .sort-indicator {
    position: relative;
    overflow: visible;
    min-width: 10px;
    width: 10px;
    height: 15px;
    margin-left: 5px;
    &:after {
      bottom: 0;

      position: absolute;
      right: 0;
      content: "";
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
    }
    &:before {
      top: 0;

      position: absolute;
      right: 0;
      content: "";
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
    }
  }

  div[data-sort-allowed="true"] {
    cursor: pointer;
    & > .sort-indicator {
      &:after {
        border-top: 5px solid #e6e6e6;
      }
      &:before {
        border-bottom: 5px solid #e6e6e6;
      }
    }
  }
  div[data-sort="true"][data-order="DESC"] {
    & > .sort-indicator {
      &:before {
        border-bottom: 5px solid #c80000;
      }
    }
  }
  div[data-sort="true"][data-order="ASC"] {
    & > .sort-indicator {
      &:after {
        border-top: 5px solid #c80000;
      }
    }
  }

  & > .sort-list-open-button {
    display: none;
  }

  & > .parcel-table-titles {
    height: 50px;
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    & > div {
      height: 100%;

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      padding: 1em 0;
      box-sizing: border-box;
      & > div {
        position: relative;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: center;
      }
    }
  }
}

@media (max-width: 1224px) {
  .parcel-table-titles-container {
    padding: 0;

    & > .sort-list-open-button {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      position: relative;
      border: 1px solid lightgray;
      margin: 0 15px 20px;
      border-radius: 2em;
      padding: 0.25em 0.25em 0.25em 1em;
      font-weight: 400;
      & > .label {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        box-sizing: border-box;

        & > div {
          &:first-child {
            margin-right: 0.25em;
          }
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          box-sizing: border-box;
        }
      }
      & > img {
        transform-origin: center center;
        -webkit-transition: all 0.1s linear;
        transition: all 0.1s linear;
        height: 2.5em;
        width: 2.5em;
        border-radius: 1.25em;
        box-sizing: border-box;
        padding: 0.75em;
        background-color: rgb(230, 230, 230);
      }

      &.opened {
        margin: 0 15px;
        border-radius: 1.5em 1.5em 0 0;
        & > img {
          transform: rotate(-180deg);
        }
        & + .parcel-table-titles {
          display: flex;
        }
      }
    }

    & > .parcel-table-titles {
      height: unset;
      width: unset;
      margin: 0 15px;

      font-weight: 400;

      display: none;
      flex-direction: column;
      justify-content: flex-start;
      & > div {
        &[data-sort-allowed="false"] {
          display: none;
        }

        padding: 1em;

        border-bottom: 1px solid lightgray;
        border-right: 1px solid lightgray;
        border-left: 1px solid lightgray;

        justify-content: flex-start;
      }
    }
  }
}

@media (max-width: 800px) {
  .parcel-table-titles-container {
    font-size: 1.2em;
  }
}

@media (max-width: 480px) {
  .parcel-table-titles-container {
    font-size: 0.9em;
  }
}

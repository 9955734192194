.user-parcel-table-titles {
  width: 100%;

  padding: 0 15px 15px 0;
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  & > div {
    height: 50px;
    font-weight: 700;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 1em 0;
    box-sizing: border-box;
  }
}

@media (max-width: 1224px) {
  .user-parcel-table-titles {
    display: none;
  }
}
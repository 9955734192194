@import "src/css/constants.scss";

.user-table-view {
  position: relative;
  padding: 25px 20px 0;
  & > .user-table-container {
    min-width: 720px;
    width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    box-shadow: 0 0 10px 5px rgba(221, 221, 221, 1);
    padding: 40px;
    border-radius: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;

    .user_id {
      width: 15%;
    }
    .user_email {
      width: 30%;
    }
    .user_role {
      width: 16%;
    }
    .user_status {
      width: 16%;
    }

    & > .user-table {
      width: 100%;
      max-height: 600px;
      overflow: auto;
      margin-bottom: 20px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      & > .user-table-row:nth-child(odd) {
        background-color: rgb(230, 230, 230);
      }
    }
  }
}

@media (max-width: $toggle-menu-view-width) {
  .user-table-view {
    padding: 85px 20px 25px;
  }
}

@media (max-width: 800px) {
  .user-table-view {
    padding: 85px 0 25px;
    & > .user-table-container {
      min-width: unset;
      box-shadow: none;
      padding: 0;

      .user_id,
      .user_email,
      .user_role,
      .user_status {
        box-sizing: border-box;
        width: 100%;
        padding: .125em 1em;
        text-align: left;
      }

      & > .user-table {
        max-height: unset;
        padding: 0 20px;
        & > .user-table-row:nth-child(odd) {
          background-color: white;
        }
      }
    }
    & div.popup-content {
      width: 90%;
    }
  }
}

@media (max-width: 480px) {
  .user-table-view {
    font-size: .75em;
    & div.popup-content {
      width: 80%;
    }
  }
}
$edit-tool-height: 2em;

.status-edit-tool {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  & > .title {
    font-size: 1.5em;
    font-weight: 700;

    margin-bottom: 10px;
    width: 100%;
  }

  & > .select-button-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    width: 100%;
    height: $edit-tool-height;

    & > .custom-select {
      width: 60%;
      border-top-left-radius: 1.5em;
      border-bottom-left-radius: 1.5em;
      padding-left: 0;

      background: white;

      border: 1px solid #fc0;

      & > .select {
        & > .select-short-view {
          border-top-left-radius: 1.5em;
          border-bottom-left-radius: 1.5em;
          padding: 0 1.5em 0 1em;
        }

        & > .select-detailed-view {
          border-top-left-radius: 0.75em;
          border-bottom-left-radius: 0.75em;

          & > .option {
            padding: .25em .25em .25em 1em;
          }
        }
      }

      &.opened {
        padding: 0;
      }
    }

    .button-popup-container {
      width: 40%;
      height: 100%;
      & > button {
        width: 100%;
        height: 100%;

        padding: .25em;

        border-top-right-radius: 1.5em;
        border-bottom-right-radius: 1.5em;
      }
    }

    .status-edit-loader {
      width: 40%;
      height: 100%;

      border-top-right-radius: 1.5em;
      border-bottom-right-radius: 1.5em;

      background-color: lightgray;

      .loader {
        width: $edit-tool-height;
        height: $edit-tool-height;

        margin: auto;
        .lds-ring {
          div {
            border: $edit-tool-height / 15 solid;
            border-color: black transparent transparent transparent;
          }
        }
      }
    }
  }
}